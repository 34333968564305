import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import MainComponet from "./modules/main";
// import GlobalStyle from "./core/styled/globalStyles";
import { getTheme } from "./core/styled/theme";
import { getLocalizationData, setLocalizationlanguage, setMapLoaded } from "@core-action";
import {
  getLocalizationLanguage,
  getLocalizationList,
} from "./modules/localization/localization.selector";
import loadable from "@loadable/component";
import { getCookiesValue, setCookiesValue } from "app-core/constant/utills";

const GlobalStyle = loadable(
  () => import(/*webpackChunkName: "pkg_____common"*/ "./core/styled/globalStyles")
);
declare global {
  interface Window {
    initMap: any;
  }
}

let win: any = window;

function App() {
  const dispatch = useDispatch();
  const languageList = useSelector(getLocalizationList);
  const globleLang = getCookiesValue('globleLang');

  const cultureMapper = (browserChange: boolean) => {
    // const defaultCulture = window.navigator.language
    // let newCulture: string;
    // if (defaultCulture?.startsWith('en')) {
    //   newCulture = 'En_Us';
    // } else if (defaultCulture?.startsWith('de')) {
    //   newCulture = 'De_ch';
    // } else {
    //   newCulture = 'De_ch';
    // }
    // if(browserChange){
    //   dispatch(setLocalizationlanguage(newCulture))
    //   setCookiesValue('lang', newCulture);
    //   setCookiesValue('globleLang', newCulture);
    // } else {
    //   if(!globleLang) {
    //     dispatch(setLocalizationlanguage(newCulture))
    //     setCookiesValue('globleLang', newCulture);
    //     setCookiesValue('lang', newCulture);
    //   }else dispatch(setLocalizationlanguage(globleLang))
    // }
        dispatch(setLocalizationlanguage('En_Us'))
        setCookiesValue('globleLang', 'En_Us');
        setCookiesValue('lang', 'En_Us');
  }

  useEffect(() => {
    // var location = window.onfocus();
    // console.log('window.onfocus()', window.onfocus())
    dispatch(getLocalizationData());
    cultureMapper(false);
  }, []);

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logoutChannel');
    const handleLogoutEvent = () => {
      window.open('/', '_self');
    };
    const handleLanguageChange = () => {
      cultureMapper(true);
    };

    logoutChannel.addEventListener('message', handleLogoutEvent);
    window.addEventListener('languagechange', handleLanguageChange);
    return () => {
      logoutChannel.removeEventListener('message', handleLogoutEvent);
      logoutChannel.close();
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    win.initMap = () => {
      dispatch(setMapLoaded(true));
    };
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAjmXiD-nVEaLyalBEB8mUDtLkvCtjID6I&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }, []);

  useEffect(() => {
    if (Object.keys(languageList).length > 0 ) {
      const loader = document.getElementById('loader')
      loader.style.display = 'none';
    }
    console.log('v_4.9.4');
  }, [JSON.stringify(languageList)]);

  return (
    <ThemeProvider theme={getTheme()}>
      <LocalizationProvider language={useSelector(getLocalizationLanguage)}>
        <GlobalStyle />
        <BrowserRouter>
          {Object.keys(languageList).length > 0 && <MainComponet />}
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
