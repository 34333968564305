import { PatientProfileReducer } from "@core-interface";
import {
  SET_PATIENT_PROFILE_DATA,
  SET_UPDATE_PATIENT_PROFILE_DATA,
  UPDATE_PATIENT_PROFILE_DATA,
  SET_DENTIST_LIST_DATA,
  GET_DENTIST_LIST_DATA,
  SET_PROFILE_IMAGE,
} from "./patientProfile.actions";
import { updateSession } from "app-core/constant/utills";

const initState: PatientProfileReducer = {
  patientProfileData: {
    Id: "Id",
    Address: "Address",
    EmailAddress: "EmailAddress",
    FirstName: "FirstName",
    LastName: "LastName",
    Longitude: 0,
    Latitude: 0,
    Location: "",
  },
  getDentistListLoading: true,
  updateDentistLoading: false,
  dentistList: [],
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PATIENT_PROFILE_DATA:
      if (payload?.ChatAppId) {
        updateSession({ ChatAppId: payload.ChatAppId })
      }else updateSession({ ChatAppId: 0 })
      return {
        ...state,
        patientProfileData: payload,
      };
    case UPDATE_PATIENT_PROFILE_DATA:
      return {
        ...state,
        updateDentistLoading: true,
      };
    case SET_UPDATE_PATIENT_PROFILE_DATA:
      if (payload?.ChatAppId) {
        updateSession({ ChatAppId: payload.ChatAppId });
      }else updateSession({ ChatAppId: 0 });

      if (payload?.ProfileImage){
        updateSession({ picture: payload.ProfileImage });
      }
      updateSession({ nickName: payload.FirstName });
      return {
        ...state,
        updateDentistLoading: false,
        patientProfileData: { ...state.patientProfileData, ...payload },
      };
    case GET_DENTIST_LIST_DATA: 
    return {
      ...state,
      getDentistListLoading: true,
      dentistList: [],
    };
    case SET_PROFILE_IMAGE: 
    return {
      ...state,
      patientProfileData: { ...state.patientProfileData, ProfileImage: payload },
    };
    case SET_DENTIST_LIST_DATA:
      return {
        ...state,
        getDentistListLoading: false,
        dentistList: payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
